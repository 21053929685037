.subscribe-wrap {
  position: relative;
  background: transparentize(#000, .2);
  padding: 30px 25px;
  border-radius: 5px;
  width: 360px;
  max-width: 90%;
  color: #03e9f4;
  transition: height 2s ease-in-out;

  h1 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 800;
  }

  h2 {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    margin: 10px 0 30px;
    padding-bottom: 15px;
    position: relative;

    img {
      height: 30px;
      position: absolute;
      top: 20px;
    }

    input {
      border: 0;
      box-shadow: none;
      outline: none;
      background: transparent;
      border-radius: 0;
      margin-bottom: 10px;
      padding: 10px 0 8px 40px;
      font-size: 30px;
      color: #03e9f4;

      &:focus {
        background: transparent;
        outline: none;
        border: 0;
        box-shadow: none;
        color: #03e9f4;
      }

      &::placeholder {
        color: transparentize(#fff, .7);
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
      transition: background-color 5000s;
      -webkit-text-fill-color: #03e9f4 !important;
    }

    span.underline {
      display: block;
      position: relative;
      top: -10px;

      &:after {
        display: block;
        margin-bottom: 10px;
        content: '';
        height: 1px;
        width: 100%;
        background: #03e9f4;
        box-shadow: 0 0 3px 1px transparentize(#03e9f4, .9);
      }

      &.underline-alert-danger {
        &:after {
          background: #f44336;
          box-shadow: 0 0 3px 1px transparentize(#f44336, .9);
        }
      }
    }

    button[type="submit"] {
      background: transparentize(#03e9f4, .9);
      border: 0;
      display: block;
      width: 100%;
      transition: .25s;
      position: relative;
      overflow: hidden;
      text-transform: uppercase;
      padding: 10px 0;
      color: #03e9f4;
      font-weight: 800;

      &:active {
        background: transparentize(#03e9f4, .8);

        span {
          animation: none;
          display: none;
        }
      }

      &:hover,
      &:focus {
        background: transparentize(#03e9f4, .8);
      }

      &:focus {
        box-shadow: none;
      }

      span {
        position: absolute;
        display: block;

        &:nth-child(1) {
          top: 0;
          left: -100%;
          height: 2px;
          width: 100%;
          background: linear-gradient(90deg,transparent,#03e9f4);
          animation: btn-anim1 2s linear infinite;
        }

        &:nth-child(2) {
          top: -100%;
          right: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(180deg,transparent,#03e9f4);
          animation: btn-anim2 2s linear infinite;
          animation-delay: .5s;
        }

        &:nth-child(3) {
          bottom: 0;
          right: -100%;
          width: 100%;
          height: 2px;
          background: linear-gradient(270deg,transparent,#03e9f4);
          animation: btn-anim3 2s linear infinite;
          animation-delay: .1s;
        }

        &:nth-child(4) {
          bottom: -100%;
          left: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(360deg,transparent,#03e9f4);
          animation: btn-anim4 2s linear infinite;
          animation-delay: 1.5s;
        }
      }
    }
  }

  .success-message {
    h1 {
      font-size: 1.5rem;
    }

    h2 {
      margin-bottom: 0;
    }
  }

  .alert {
    background: transparent;
    text-align: center;
    border: 0;
    font-size: 1.4rem;
    padding: 0;
    font-weight: 800;

    &.alert-success {
      color: #03e9f4;
    }

    &.alert-danger {
      margin-top: 20px;
      font-style: italic;
      color: #f44336;
      font-weight: 300;
      font-size: 16px;
    }
  }

  .spinner-wrapper {
    text-align: center;
    margin-bottom: 10px;
  }

  .disclaimer {
    font-size: 12px;
    margin: 0;
    line-height: 1.1;
    text-align: center;
    color: transparentize(#fff, .5);

    + .disclaimer {
      margin-top: 5px;
    }

    &:nth-of-type(1) {
      border-top: 1px solid transparentize(#fff, .5);
      padding: 10px;
    }
  }
}

@keyframes btn-anim1{
  0%{
      left: -100%;
  }
  50%,100%{
      left: 100%;
  }
}

@keyframes btn-anim2{
  0%{
      top: -100%;
  }
  50%,100%{
      top: 100%;
  }
}

@keyframes btn-anim3{
  0%{
      right: -100%;
  }
  50%,100%{
      right: 100%;
  }
}

@keyframes btn-anim4{
  0%{
      bottom: -100%;
  }
  50%,100%{
      bottom: 100% ;
  }
}


