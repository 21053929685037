.slideshow {
  list-style-type: none;
  padding: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;

  li {
    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      color: transparent;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: none;
      opacity: 0;
      z-index: 0;
      animation: imageAnimation 24s linear infinite 0s;
    }
  }
  li:nth-child(1) {
    span {
      background-image: url('../../assets/images/bg_slide_1.jpg');
    }
  }

  li:nth-child(2) {
    span {
      background-image: url('../../assets/images/bg_slide_2.jpg');
      animation-delay: 6s;
    }
  }

  li:nth-child(3) {
    span {
      background-image: url('../../assets/images/bg_slide_3.jpg');
      animation-delay: 12s;
    }
  }

  li:nth-child(4) {
    span {
      background-image: url('../../assets/images/bg_slide_4.jpg');
      animation-delay: 18s;
    }
  }
}

@keyframes imageAnimation {
	0% {
    opacity: 0;
    animation-timing-function: ease-in;
	}
	8% {
    opacity: 1;
    transform: scale(1.05);
    animation-timing-function: ease-out;
	}
	17% {
    opacity: 1;
    transform: scale(1.1);
	}
	50% {
    opacity: 0;
    transform: scale(1.1);
	}
	100% { opacity: 0 }
}